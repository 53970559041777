<template>
    <header :class="`pr--header ${!system.header ? 'pr--header__hidden' : ''} ${true ? 'pr-shadow-sm' : ''}`">
        <nav :class="`navbar ${true ? 'container' : 'pr-shadow-sm'}`">
            <div :class="`${true ? 'pl-3 pr-3 w-100 d-flex align-items-center justify-content-between' : 'container'}`">
                <div class="d-flex align-items-center justify-content-start">
                    <div class="mr-3 d-block d-xl-none">
                        <BaseHeaderMobileMenu />
                    </div>
                    <div class="mr-3">
                        <NuxtLink v-if="dataIndex.data.config !== null" class="navbar-brand d-flex align-items-center" :to="i18nRoute.home()">
                            <span class="mr-2">
                                <img
                                    v-if="system.theme === 'light' || system.theme === 'system'"
                                    :src="dataIndex.data.config.LOGO.light"
                                    :alt="dataIndex.data.config.META.description ?? dataIndex.data.config.NAME"
                                    class="rounded-circle"
                                    width="30"
                                >
                                <img
                                    v-if="system.theme === 'dark'"
                                    :src="dataIndex.data.config.LOGO.dark"
                                    :alt="dataIndex.data.config.META.description ?? dataIndex.data.config.NAME"
                                    class="rounded-circle"
                                    width="30"
                                >
                            </span>
                            <span class="d-flex align-items-center">
                                <span style="margin-top: 1px;">
                                    {{ dataIndex.data.config.NAME }}
                                </span>
                            </span>
                        </NuxtLink>
                    </div>
                    <div v-if="responsible.desktop() && dataIndex.data.header !== null" class="pr--navigation">
                        <div v-for="(field, key) in dataIndex.data.header.groups.fields" :key="key" :class="`pr--navigation--item ${field.children.length ? '__dropable' : ''}`">
                            <button v-if="field.children.length" :class="`btn btn-outline-light-darken mr-1 pr--navigation--button`" @click="system.setNavigationActive(key)">
                                {{ field.title }}
                                <IconFontAwesome name="chevron-down" />
                            </button>
                            <NuxtLink v-if="!field.children.length" :to="field.url" class="btn btn-outline-light-darken mr-1 pr--navigation--button">
                                {{ field.title }}
                            </NuxtLink>
                            <div v-if="!system.navigation.changed" v-on-click-outside="closeModal" :class="`pr--navigation--item-panel pr-shadow-sm`">
                                <div>
                                    <ul class="pr--navigation--container">
                                        <li v-for="(child, k) in field.children" :key="k">
                                            <div v-if="child.children !== undefined && child.children.length">
                                                <ul class="pr--navigation--container __children">
                                                    <li v-for="(c, l) in child.children" :key="l">
                                                        <div v-if="c.children == undefined">
                                                            <NuxtLink v-if="c.url === child.url" :to="c.url" class="pr--navigation--link">
                                                                - {{ child.title }}
                                                            </NuxtLink>
                                                            <NuxtLink v-if="c.url !== child.url" :to="c.url" class="pr--navigation--link">
                                                                - {{ c.title }}
                                                            </NuxtLink>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div v-if="child.children !== undefined && child.children.length === 0">
                                                <NuxtLink :to="child.url" class="pr--navigation--link">
                                                    {{ child.title }}
                                                </NuxtLink>
                                            </div>
                                            <div v-if="child.children === undefined">
                                                <NuxtLink :to="child.url" class="pr--navigation--link">
                                                    {{ child.title }}
                                                </NuxtLink>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="d-flex align-items-center justify-content-end">
                        <div v-if="version.needReload" class="mr-2">
                            <BaseHeaderStateVersion />
                        </div>
                        <!--                        <div v-if="system.route.name !== 'search'" class="mr-2 d-none d-lg-block">-->
                        <!--                            &lt;!&ndash;                        <div v-if="!responsible.mobile()" class="mr-2">&ndash;&gt;-->
                        <!--                            <form @submit.prevent="searchSubmit">-->
                        <!--                                <div class="input-group pr&#45;&#45;input-group&#45;&#45;line ">-->
                        <!--                                    <input-->
                        <!--                                        v-model="search.form.q"-->
                        <!--                                        type="text"-->
                        <!--                                        :placeholder="'Поиск'"-->
                        <!--                                        aria-label="Search"-->
                        <!--                                        class="form-control"-->
                        <!--                                    >-->
                        <!--                                    <div class="input-group-append">-->
                        <!--                                        <button type="submit" class="btn btn-outline-light-darken">-->
                        <!--                                            <IconFontAwesome name="search" class="__md" />-->
                        <!--                                        </button>-->
                        <!--                                    </div>-->
                        <!--                                </div>-->
                        <!--                            </form>-->
                        <!--                        </div>-->
                        <div class="-mr-2 -pr-2">
                            <BaseHeaderTheme />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import Popper from 'vue3-popper';
import BaseHeaderMobileMenu from '@/components/Base/Header/Button/BaseHeaderMobileMenu.vue';
import BaseHeaderStateVersion from '@/components/Base/Header/Button/BaseHeaderStateVersion.vue';
import BaseHeaderTheme from '@/components/Base/Header/Button/BaseHeaderTheme.vue';
import IconFontAwesome from '@/components/Icons/IconFontAwesome.vue';
import useI18nRoute from '@/hooks/i18nRoute';
import useResponsible from '@/hooks/responsible';
import { useStoreConfig } from '@/stores/app/config';
import { useStoreAppDataIndex } from '@/stores/app/data';
import { useStoreAppVersion } from '@/stores/app/version';
import { useStoreSearchIndex } from '@/stores/search';
import { useStoreSystem } from '@/stores/system';
// import { useElementHover } from '@vueuse/core';

// defineProps<{
//     title: string,
//     short?: false,
// }>();

export interface Props {
    title: string
    short?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    title: '',
    short: false
});

const dataIndex = useStoreAppDataIndex();
const i18nRoute = useI18nRoute();
const version = useStoreAppVersion();
const system = useStoreSystem();
const config = useStoreConfig();
const responsible = useResponsible();
const search = useStoreSearchIndex();
const router = useRouter();

async function searchSubmit() {
    await router.push({
        path: '/search',
        query: search.form,
    });
}

function closeModal() {
    system.setNavigationActive(null);
}

</script>
