import { useStoreAppSidebarIndex } from '@/stores/app/sidebar';

export default function useSidebarActions() {
    const open = (name: string) => useStoreAppSidebarIndex().setCurrent(name);
    const close = () => {
        console.log('close');
        if (useStoreAppSidebarIndex().currentCurrent !== null) {
            useStoreAppSidebarIndex().setCurrentCurrent(null);
        } else {
            useStoreAppSidebarIndex().setCurrent(null);
            bodyClassRemove('body--' + useStoreAppSidebarIndex().names.panel.editor);
        }
    };
    const closed = (name: string) => {
        console.log('closed', name);
        // if (useStoreAppSidebarIndex().currentCurrent !== null) {
        //     useStoreAppSidebarIndex().setInstanceInstance(null);
        //     useStoreAppSidebarIndex().setCurrentCurrent(null);
        // } else {
        //     if (useStoreAppSidebarIndex().current !== null) {
        //         useStoreAppSidebarIndex().setInstance(null);
        //         useStoreAppSidebarIndex().setCurrent(null);
        //         bodyClassRemove('body--' + useStoreAppSidebarIndex().names.panel.editor);
        //     }
        // }
        //
        // if (useStoreAppSidebarIndex().currentCurrent !== null) {
        //     useStoreAppSidebarIndex().setActiveActive(null);
        //     useStoreAppSidebarIndex().setInstanceInstance(null);
        //     useStoreAppPanelPanelBase().setOpened(false);
        // } else {
        //     useStoreAppSidebarIndex().setActive(null);
        //     useStoreAppSidebarIndex().setInstance(null);
        //     useStoreAppPanelBase().setOpened(false);
        // }
    };
    const openPanelMobileMenu = () => {
        useStoreAppSidebarIndex().setQueue(useStoreAppSidebarIndex().names.panel.mobile.menu);
    };
    const closePanelMobileMenu = () => {
        useStoreAppSidebarIndex().setQueueClose(useStoreAppSidebarIndex().names.panel.mobile.menu);
    };
    const bodyClassAdd = (name: string) => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add(name);
    };
    const bodyClassRemove = (name: string) => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove(name);
    };
    return {
        open,
        close,
        closed,
        openPanelMobileMenu,
        closePanelMobileMenu,
    };
}
