<template>
    <div id="common-breadcrumbs" />
</template>

<script setup lang="ts">

import { watch } from 'vue';
import { useStoreAppBreadcrumbsIndex } from '@/stores/app/breadcrumbs';
import { useStoreAppDataIndex } from '@/stores/app/data';

const breadcrumbs = useStoreAppBreadcrumbsIndex();
const dataIndex = useStoreAppDataIndex();

// function setMeta() {
//     useSeoMeta({
//         title: breadcrumbs.getTitle + ' - ' + dataIndex.data.config?.NAME,
//     });
// }
//
// if (process.client) {
//     //setMeta();
//     watch(() => breadcrumbs.getTitle, () => {
//         // setMeta();
//     });
// }
</script>
