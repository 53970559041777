<template>
    <div id="common-search" />
</template>

<script setup lang="ts">

import { watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStoreSearchIndex } from '@/stores/search';

const route = useRoute();
const search = useStoreSearchIndex();

if (process.client) {
    watch(() => route.fullPath, () => {
        if (route.name !== 'search') {
            search.form.q = '';
        }
    });
}

</script>
