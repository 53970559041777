<template>
    <div>
        <BButton :variant="'light'" @click="opened">
            <IconFontAwesome :name="'bars'" />
        </BButton>
        <BOffcanvas v-model="sidebar.opened" :body-class="'p-0'">
            <template #header>
                <h5 class="modal-title">
                    Меню
                </h5>
                <button type="button" class="btn-close" @click="opened" />
            </template>
            <div class="h-100">
                <nav aria-label="Main navigation" class="h-100">
                    <div class="h-100 pr--nav-left--container">
                        <div v-if="dataIndex.data.sidebar !== null">
                            <LayoutSidebarMenu />
                        </div>
                    </div>
                </nav>
            </div>
        </BOffcanvas>
    </div>

<!--    -->
<!--    <button-->
<!--        type="button"-->
<!--        :class="[-->
<!--            'btn btn-icon btn-outline-light-darken __focus-none',-->
<!--        ]"-->
<!--        @click="openPanel"-->
<!--    >-->
<!--        <font-awesome-icon icon="bars" />-->
<!--    </button>-->
</template>

<script setup lang="ts">
import { BButton, BOffcanvas } from 'bootstrap-vue-next';
import { defineComponent, ref } from 'vue';
import IconFontAwesome from '@/components/Icons/IconFontAwesome.vue';
import useSidebarActions from '@/hooks/sidebarActions';
import { useStoreAppDataIndex } from '@/stores/app/data/index.ts';
import { useStoreAppSidebarIndex } from '@/stores/app/sidebar/index.ts';

const sidebarActions = useSidebarActions();
const show = ref(false);
const dataIndex = useStoreAppDataIndex();
const sidebar = useStoreAppSidebarIndex();

const opened = () => {
    sidebar.setOpened(!sidebar.opened);
};
</script>
