import { defineStore, acceptHMRUpdate } from 'pinia';
import { type PersistedStateOptions } from 'pinia-plugin-persistedstate/dist';
import api from '@/api';
import axios from '@/plugins/common/axios';
import cookiesPinia from '@/plugins/cookies/pinia';
import formatDate from '@/plugins/format/date';
import useAppLogger from '@/composables/useAppLogger';

interface State {
    data: object|null,
    version: string|null,
}

const persist: PersistedStateOptions = {
    key: cookiesPinia.NAMES.API_VERSION,
    debug: true,
    // @ts-expect-error
    storage: persistedState.localStorage,
    serializer: {
        deserialize: (value: string) => {
            useAppLogger().piniaLocalStorage('config:deserialize', value, JSON.parse(value));
            return JSON.parse(value);
        },
        serialize: (value: any) => {
            useAppLogger().piniaLocalStorage('config:serialize', value, JSON.stringify(value));
            return JSON.stringify(value);
        },
    },
    beforeRestore: (ctx: any) => {
        useAppLogger().piniaLocalStorage(`config:beforeRestore '${ctx.store.$id}'`);
    },
    afterRestore: (ctx: any) => {
        useAppLogger().piniaLocalStorage(`config:afterRestore '${ctx.store.$id}'`);
    }
};

export const useStoreApiVersionIndex = defineStore({
    id: 'api/version/index',

    state: (): State => ({
        data: null,
        version: null,
    }),

    getters: {
        //
    },

    actions: {
        async fetch(callback: any) {
            const self = this;
            const url = api.index.version;
            await axios.get(url, {}, (result: any) => {
                self.data = result.data;
                self.version = formatDate().toString((new Date()));
                callback(result.data);
            }, () => {
                //
            });
        },
        isEmpty() {
            if (this.data === null) {
                return true;
            }
            if (this.version === null) {
                return true;
            }
            return false;
        },
        checkVersion(version: string) {
            if (this.version === null) {
                return false;
            }
            return this.version === version;
        }
    },
    persist,
});
if (import.meta.hot) {
    import.meta.hot.accept(
        acceptHMRUpdate(useStoreApiVersionIndex, import.meta.hot),
    );
}
