<template>
    <BaseSidebar :name="sidebar.names.panel.mobile.menu" :placement="'left'" :width="300" :loaded="true">
        <template #header>
            <h5 class="modal-title">
                Меню
            </h5>
            <button type="button" class="btn-close" @click="sidebarActions.close()" />
        </template>
        <template #body>
            <div v-if="sidebar.opened">
                <LayoutSidebarMenu />
            </div>
        </template>
        <template #footer>
            <BaseSidebarFooter :pending="false" :has-submit="false" :cancel-title="'Закрыть'" :placement="'right'" />
        </template>
    </BaseSidebar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseSidebar from '@/components/Base/Sidebar/BaseSidebar.vue';
import BaseSidebarFooter from '@/components/Base/Sidebar/BaseSidebarFooter.vue';
import LayoutSidebarMenu from '@/components/Layout/Sidebar/LayoutSidebarMenu.vue';
import useSidebarActions from '@/hooks/sidebarActions';
import { useStoreAppSidebarIndex } from '@/stores/app/sidebar';

export default defineComponent({
    name: 'BaseSidebarMobileMenu',
    components: {
        LayoutSidebarMenu,
        BaseSidebarFooter,
        BaseSidebar,
    },
    setup() {
        const sidebarActions = useSidebarActions();
        const sidebar = useStoreAppSidebarIndex();
        return {
            sidebar,
            sidebarActions,
        };
    },
});
</script>