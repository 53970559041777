import fs from 'fs';
import path from 'path';
import axios from '@/plugins/common/axios';
import { useStoreApiVersionIndex } from '@/stores/api/version';

export default function useAppVersion() {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const isProduction = process.env.NODE_ENV === 'production';

    const getDataFromLocalStorage = async (callback: any) => {
        const store = useStoreApiVersionIndex();
        if (store.isEmpty()) {
            await store.fetch((data: any) => {
                callback(data);
            });
        } else {
            callback(store.data);
        }
    };
    const getDataFromJson = async (callback: any) => {
        let data = null;
        if (isProduction) {
            data = await JSON.parse(fs.readFileSync(path.resolve(process.cwd(), 'public/version.json'), 'utf-8'));
        } else {
            data = await JSON.parse(fs.readFileSync('src/public/version.json', 'utf-8'));
        }
        callback(data);
    };
    const getDataFromUrl = async (callback: any) => {
        let dir = 'src/public/version.json';
        dir = dir.replace('src/public', '');
        await axios.getLocal(dir, {}, (data: any) => {
            callback(data);
        });
        // return data.value;
    };

    const getData = async (callback: any) => {
        if (!process.client && isDevelopment) {
            await getDataFromJson(callback);
        }
        if (process.client && isDevelopment) {
            await getDataFromUrl(callback);
        }
        if (!process.client && isProduction) {
            await getDataFromJson(callback);
        }
        if (process.client && isProduction) {
            await getDataFromUrl(callback);
        }
    };

    return {
        getData,
    };
}
