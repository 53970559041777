<template>
    <div
        :id="name"
        :class="`offcanvas offcanvas-${placement === 'right' ? 'end' : 'start'} ${name} ${isChild ? 'offcanvas-child' : ''}`"
        tabindex="-1"
        :data-bs-backdrop="backdrop ? 'true' : 'false'"
        :data-bs-scroll="backdrop ? 'false' : 'true'"
        :style="{width: `${width}px`, marginRight: `${sidebar.currentCurrent !== null && sidebar.current === name && !isChild ? 5 : 0}rem`}"
    >
        <div v-if="loaded" class="offcanvas-header">
            <slot name="header" />
        </div>
        <div v-if="loaded && (sidebar.current === name || sidebar.currentCurrent === name)" class="offcanvas-body">
            <slot name="body" />
        </div>
        <div v-if="loaded && footer" class="offcanvas-footer">
            <slot name="footer" />
        </div>
        <div v-if="backdrop" class="offcanvas-backdrop-close" @click="sidebarActions.close()" />
    </div>
</template>

<script>
import { defineComponent, toRefs, watch, ref, onMounted } from 'vue';
import useSidebarActions from '@/hooks/sidebarActions';
import { useStoreAppSidebarIndex } from '@/stores/app/sidebar';

export default defineComponent({
    components: {
        //
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        placement: {
            type: String,
            default: () => 'right',
        },
        width: {
            type: Number,
            default: () => {
                return 600;
            }
        },
        footer: {
            type: Boolean,
            default: () => true,
        },
        backdrop: {
            type: Boolean,
            default: () => true,
        },
        loaded: {
            type: Boolean,
            default: () => false,
        },
        isChild: {
            type: Boolean,
            default: () => false,
        }
    },
    setup(props) {
        const {
            name,
        } = toRefs(props);
        const sidebar = useStoreAppSidebarIndex();
        const sidebarActions = useSidebarActions();
        const additionalWidth = ref(null);

        watch(() => sidebar.currentCurrent, () => {
            additionalWidth.value = 0;
            if (sidebar.current === name.value) {
                additionalWidth.value = 5;
            }
        });

        return {
            sidebar,
            additionalWidth,
            sidebarActions
        };
    },
});
</script>
