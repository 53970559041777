<template>
    <div id="common-sidebar">
        <BaseSidebarMobileMenu />
    </div>
</template>

<script setup lang="ts">
// import { Offcanvas } from 'bootstrap/dist/js/bootstrap.esm.js';
import { defineComponent, watch } from 'vue';
import BaseSidebarMobileMenu from '@/components/Base/Sidebar/BaseSidebarMobileMenu.vue';
import useSidebarActions from '@/hooks/sidebarActions';
import { useStoreAppSidebarIndex } from '@/stores/app/sidebar';
import { useStoreSystem } from '@/stores/system';

const system = useStoreSystem();
const sidebar = useStoreAppSidebarIndex();
const sidebarActions = useSidebarActions();

function setQueue() {
    const queue = sidebar.queue;
    const current = sidebar.current;
    const instance = sidebar.instance;
    if (queue !== null) {
        if (current !== null && instance !== null) {
            instance.hide();
        }
        sidebar.setCurrent(queue);
        sidebar.setQueue(null);
    }
}
function setQueueClose() {
    const queueClose = sidebar.queueClose;
    const current = sidebar.current;
    const instance = sidebar.instance;
    console.log(queueClose);
    // if (queueClose !== null) {
    //     if (current !== null && instance !== null) {
    //         instance.hide();
    //     }
    //     sidebar.setCurrent(null);
    //     sidebar.setQueue(null);
    //     sidebar.queueClose = null;
    // }
}
function setCurrent() {
    const current = sidebar.current;
    const instance = sidebar.instance;
    if (current === null && instance !== null) {
        instance.hide();
        //sidebar.setActive(null);
        //sidebar.setInstance(null);
        //panel.setOpened(false);
    }
    if (current !== null) {
        const el = document.getElementById(current);
        const modalEl = sidebar.instance !== null ? sidebar.instance : new Offcanvas(el);
        if (!el.classList.contains('initialized')) {
            el.addEventListener('hide.bs.offcanvas', () => {
                if (sidebar.current !== null) {
                    sidebar.setCurrent(null);
                }
                sidebar.setInstance(null);
                sidebar.setActive(null);
                // panel.setOpened(false);
                sidebar.setOpened(false);
            });
            el.classList.add('initialized');
        }
        modalEl.show();
        const backdrop = modalEl._backdrop._getElement();
        backdrop.classList.add('backdrop-panel');
        sidebar.setActive(current);
        sidebar.setInstance(modalEl);
        // panel.setOpened(true);
        sidebar.setOpened(true);
    }
}
function setCurrentCurrent() {
    const current = sidebar.currentCurrent;
    const instance = sidebar.instanceInstance;
    if (current === null && instance !== null) {
        instance.hide();
        //sidebar.setActiveActive(null);
        //sidebar.setInstanceInstance(null);
        //panelPanel.setOpened(false);
    }
    if (current !== null) {
        const el = document.getElementById(current);
        const modalEl = new Offcanvas(el);
        if (!el.classList.contains('initialized')) {
            el.addEventListener('hide.bs.offcanvas', () => {
                //console.log('hide', el);
                if (sidebar.currentCurrent !== null) {
                    sidebar.setCurrentCurrent(null);
                }
                sidebar.setInstanceInstance(null);
                sidebar.setActiveActive(null);
                // panelPanel.setOpened(false);
            });
            el.classList.add('initialized');
        }
        modalEl.show();
        const backdrop = modalEl._backdrop._getElement();
        backdrop.classList.add('backdrop-panel-panel');
        sidebar.setActiveActive(current);
        sidebar.setInstanceInstance(modalEl);
        // panelPanel.setOpened(true);
    }
}

watch(() => sidebar.queue, () => {
    setQueue();
});
watch(() => sidebar.queueClose, () => {
    setQueueClose();
});
watch(() => sidebar.current, () => {
    setCurrent();
});
watch(() => sidebar.currentCurrent, () => {
    setCurrentCurrent();
});
</script>
