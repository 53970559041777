import { defineStore, acceptHMRUpdate } from 'pinia';

interface State {
    opened: boolean
    openedOpened: boolean
    queue: string|null
    queueClose: string|null
    current: string|null
    currentCurrent: string|null
    instance: any
    instanceInstance: any
    active: string|null
    activeActive: string|null
    disabled: boolean
    names: any
    item: any
}

export const useStoreAppSidebarIndex = defineStore({
    id: 'app/sidebar/index',
    state: (): State => {
        return {
            opened: false,
            openedOpened: false,
            queue: null,
            queueClose: null,
            current: null,
            currentCurrent: null,
            instance: null,
            instanceInstance: null,
            active: null,
            activeActive: null,
            disabled: false,
            names: {
                panel: {
                    mobile: {
                        menu: 'sidebar--panel-mobile-menu',
                    },
                },
            },
            item: {}
        };
    },

    getters: {
        //current: () => this.current,
        //active: () => this.active,
        //item: () => this.item,
    },

    actions: {
        async fetch() {
            //const self = this;
            //
        },
        setOpened(value: boolean) {
            this.opened = value;
        },
        setActive(name: string|null) {
            this.active = name;
        },
        setQueue(name: string|null) {
            this.queue = name;
        },
        setQueueClose(name: string|null) {
            this.queueClose = name;
        },
        setCurrent(name: string|null) {
            this.current = name;
        },
        setInstance(value: any) {
            this.instance = value;
        },
        setActiveActive(name: string|null) {
            this.activeActive = name;
        },
        setCurrentCurrent(name: string|null) {
            this.currentCurrent = name;
        },
        setInstanceInstance(value: any) {
            this.instanceInstance = value;
        },
    }
});
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStoreAppSidebarIndex, import.meta.hot));
}
