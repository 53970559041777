<template>
    <VueToggleButton
        v-tippy="'Переключить тему'"
        class="toggled-theme"
        :sync="true"
        :value="dark"
        :css-colors="true"
        :width="40"
        :labels="false"
        @change="changeTheme"
    />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import VueToggleButton from '@/plugins/vendor/vue-js-toggle-button/VueToggleButton.vue';
import { useStoreSystem } from '@/stores/system';

const storeSystem = useStoreSystem();

const dark = ref(storeSystem.theme === 'dark');

function changeTheme() {
    dark.value = !dark.value;
    if (dark.value) {
        storeSystem.setThemeDark();
    } else {
        storeSystem.setThemeLight();
    }
}

</script>
