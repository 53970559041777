<template>
    <Popper
        arrow
        hover
        show
        @open:popper="() => {}"
        @close:popper="() => {}"
    >
        <button type="button" class="btn btn-sm btn-light" @click="reload">
            Обновить версию
        </button>
        <template #content="{ close }">
            <div class="pr--popover-container">
                <div>
                    <div class="d-flex align-items-center justify-content-start">
                        <div class="mr-3">
                            <IconFontAwesome :name="'exclamation'" :classes="`text-warning`" />
                            <!--                            <font-awesome-icon :icon="`exclamation`" size="lg" :class="`text-warning`" />-->
                        </div>
                        <div>
                            Необходимо перезагрузить страницу
                            <br>
                            доступна новая версия
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Popper>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import Popper from 'vue3-popper';
import IconFontAwesome from '@/components/Icons/IconFontAwesome.vue';
//import { useStoreUserEcho } from '@/stores/user/echo';

export default defineComponent({
    name: 'BaseHeaderVersion',
    components: {
        IconFontAwesome,
        Popper,
    },
    setup() {
        //const userEcho = useStoreUserEcho();
        const state = ref(false);
        // const state = ref(userEcho.connected);

        // if (userEcho.connected) {
        //     state.value = true;
        // }

        function reload() {
            window.location.reload();
        }

        return {
            state,
            //userEcho,
            reload,
        };
    },
});
</script>
