const VITE_APP_NAME = import.meta.env.VITE_APP_NAME;

export default {
    OPTIONS: {
        path: '/',
        //sameSite: 'strict',
        maxAge: 60 * 60 * 24 * 7,
    },
    NAMES: {
        COOKIE_BAR: `${VITE_APP_NAME}--cookies.accepted`,
        THEME: `${VITE_APP_NAME}--theme`,
        VERSION: `${VITE_APP_NAME}--version`,
        DEVELOPER_TOKEN: `${VITE_APP_NAME}--developer.token`,
    }
};
