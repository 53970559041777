<template>
    <div id="common-cookie-bar">
        <BOffcanvas
            v-model="system.cookies.show"
            :placement="'bottom'"
            :no-header="true"
            :backdrop="false"
            :class="'offcanvas-cookie'"
            :body-scrolling="true"
        >
            <div class="offcanvas-container d-flex align-items-center justify-content-between">
                <div class="mr-3">
                    Этот сайт использует cookie
                </div>
                <div>
                    <button class="btn btn-primary" type="button" @click="acceptCookies">
                        Принять
                    </button>
                </div>
            </div>
        </BOffcanvas>
    </div>
</template>

<script setup lang="ts">
import { BOffcanvas } from 'bootstrap-vue-next';
import { useNuxtApp } from 'nuxt/app';
import cookieBasicOptions from '@/plugins/cookies/cookieBasicOptions';
import { useStoreSystem } from '@/stores/system';

const { $setCookie, $getCookie } = useNuxtApp();
const system = useStoreSystem();

const accepted = $getCookie(cookieBasicOptions.NAMES.COOKIE_BAR);

if (accepted !== null) {
    system.setCookiesAccepted();
}
if (!system.cookies.accepted) {
    setTimeout(() => {
        system.cookies.show = true;
    }, 3000);
}

function acceptCookies() {
    system.cookies.show = false;
    system.cookies.accepted = true;
    $setCookie(cookieBasicOptions.NAMES.COOKIE_BAR, 1, cookieBasicOptions.OPTIONS);
}

</script>
