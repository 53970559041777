<template>
    <div :class="`d-flex align-items-center ${placement === 'left' ? 'justify-content-start' : 'justify-content-end' }`">
        <button
            v-if="actionSubmit !== null"
            type="button"
            :class="['btn btn-primary mr-2', pending ? 'is-loading' : '']"
            @click="actionSubmit"
        >
            {{ submitTitle }}
        </button>
        <button
            type="button"
            class="btn btn-light"
            @click="sidebarActions.close()"
        >
            {{ cancelTitle }}
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent, defineProps } from 'vue';
import useSidebarActions from '@/hooks/sidebarActions';

export default defineComponent({
    name: 'BaseSidebarFooter',
    components: {
        //
    },
    props: {
        submitTitle: {
            type: [String],
            default: () => 'Сохранить'
        },
        cancelTitle: {
            type: [String],
            default: () => 'Закрыть'
        },
        hasSubmit: {
            type: [Boolean],
            default: true
        },
        actionSubmit: {
            type: [Function],
            default: () => null,
        },
        pending: {
            type: Boolean,
            default: false
        },
        placement: {
            type: String,
            default: () => 'left'
        }
    },
    setup() {
        const sidebarActions = useSidebarActions();
        return {
            sidebarActions
        };
    },
});
</script>
