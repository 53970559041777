<template>
    <div id="common-before-container">
        <ThePiniaCommon />
        <TheDataCommon />
        <TheVersionCommon />
        <TheDevCommon />
        <TheThemeCommon />
        <TheBreadcrumbsCommon />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import TheBreadcrumbsCommon from '@/components/Common/TheBreadcrumbsCommon.vue';
import TheDataCommon from '@/components/Common/TheDataCommon.vue';
import TheDevCommon from '@/components/Common/TheDevCommon.vue';
import ThePiniaCommon from '@/components/Common/ThePiniaCommon.vue';
import TheThemeCommon from '@/components/Common/TheThemeCommon.vue';
import TheVersionCommon from '@/components/Common/TheVersionCommon.vue';
import { useStoreSystem } from '@/stores/system';

export default defineComponent({
    name: 'TheBeforeCommon',
    components: {
        TheDevCommon,
        ThePiniaCommon,
        TheBreadcrumbsCommon,
        TheVersionCommon,
        TheThemeCommon,
        TheDataCommon,
    },
    setup() {
        const system = useStoreSystem();
        return {
            system,
        };
    },
});
</script>
