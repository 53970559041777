<template>
    <div id="common-pinia" />
</template>

<script setup lang="ts">

import { useStoreApiDataIndex } from '@/stores/api/data';
import { useStoreApiVersionIndex } from '@/stores/api/version';
import { useStoreConfig } from '@/stores/app/config';
import { useStoreInterface } from '@/stores/app/interface';

const storeConfig = useStoreConfig();
const storeInterface = useStoreInterface();
const storeApiData = useStoreApiDataIndex();
const storeApiVersion = useStoreApiVersionIndex();

if (process.client) {
    //storeConfig.$persist();
    storeInterface.$persist();
    storeApiData.$persist();
    storeApiVersion.$persist();
    //
    // if (storeApiData.isEmpty()) {
    //     storeApiData.fetch();
    // }
}
</script>
