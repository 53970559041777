<template>
    <div id="common-data" />
</template>

<script setup lang="ts">

import { useStoreAppDataIndex } from '@/stores/app/data';
import useAppData from '@/composables/useAppData';

const storeData = useStoreAppDataIndex();

// console.log('common:data:loaded', storeData.loaded);
// if (!storeData.loaded) {
//     useAppData().setData((data: any) => {
//         console.log('data', data.config);
//         storeData.setData(data);
//     });
// }

</script>
