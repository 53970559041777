<template>
    <footer class="pr--footer pr-shadow-sm">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-4">
                    <div v-if="dataIndex.data.config !== null" class="d-inline-flex align-items-center mb-2">
                        <span class="mr-2">
                            <img
                                v-if="system.theme === 'light' || system.theme === 'system'"
                                :src="dataIndex.data.config.LOGO.light"
                                alt=""
                                class="rounded-circle"
                                width="30"
                            >
                            <img
                                v-if="system.theme === 'dark'"
                                :src="dataIndex.data.config.LOGO.dark"
                                alt=""
                                class="rounded-circle"
                                width="30"
                            >
                        </span>
                        <span class="d-flex align-items-center">
                            {{ dataIndex.data.footer.title }}
                            <small v-if="config.debug.enabled && config.debug.version" class="ml-3 text-info">
                                {{ dataIndex.data.status.title }}{{ version.vs.version !== null ? `-${version.vs.version}` : '' }}
                            </small>
                        </span>
                    </div>
                    <div v-if="dataIndex.data.footer.description !== null" class="small text-muted">
                        <div v-html="dataIndex.data.footer.description" />
                    </div>
                </div>
                <div class="col-12 col-md-8">
                    <div class="row">
                        <div v-for="(section, key) in dataIndex.data.footer.sections" :key="key" class="col-12 col-md-6 col-lg-3 mb-3">
                            <h5>{{ section.title }}</h5>
                            <ul class="list-unstyled">
                                <li v-for="(field, k) in section.fields" :key="k" class="mb-2">
                                    <NuxtLink v-if="!field.url.startsWith('https://')" :to="field.url">
                                        {{ field.title }}
                                    </NuxtLink>
                                    <a v-if="field.url.startsWith('https://')" :href="field.url" target="_blank">
                                        {{ field.title }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import { useStoreConfig } from '@/stores/app/config';
import { useStoreAppDataIndex } from '@/stores/app/data';
import { useStoreAppVersion } from '@/stores/app/version';
import { useStoreSystem } from '@/stores/system';

const dataIndex = useStoreAppDataIndex();
const system = useStoreSystem();
const config = useStoreConfig();
const version = useStoreAppVersion();
</script>
