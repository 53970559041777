<template>
    <div id="common-version" />
</template>

<script setup lang="ts">

import { useNuxtApp } from 'nuxt/app';
import { watch } from 'vue';
import { useRoute } from 'vue-router';
import cookieBasicOptions from '@/plugins/cookies/cookieBasicOptions';
import cookiesPinia from '@/plugins/cookies/pinia';
import useAppLogger from '@/composables/useAppLogger';
import useAppVersion from '@/composables/useAppVersion';
import { useStoreConfig } from '@/stores/app/config';
import { useStoreInterface } from '@/stores/app/interface';
import { useStoreAppVersion } from '@/stores/app/version';
const route = useRoute();
const { $setCookie, $getCookie, $deleteCookie } = useNuxtApp();

const storeVersion = useStoreAppVersion();
const loaded = storeVersion.vs.version !== null;

function checkVersionByCookies(data: any) {
    const cookie = JSON.parse($getCookie(cookieBasicOptions.NAMES.VERSION));
    if (cookie === null) {
        useAppLogger().version('cookie  :', null);
        useAppLogger().version('current :', data.version);
        $setCookie(cookieBasicOptions.NAMES.VERSION, JSON.stringify(data), { maxAge: 60 * 60 * 24 * 7 });
        return;
    }
    useAppLogger().version('cookie  :', cookie.version);
    useAppLogger().version('current :', data.version);
    if (cookie.version !== data.version) {
        storeVersion.setNeedReloadForce(true);
    }
}

useAppLogger().version('version:loaded', loaded);
if (!loaded && !process.client) {
    useAppVersion().getData((data: any) => {
        useAppLogger().version('version', data);
        storeVersion.setVs(data);
        checkVersionByCookies(data);
    });
}

if (process.client && storeVersion.needReloadForce) {
    storeVersion.needReloadForce = false;
}

if (process.client) {
    watch(() => route.fullPath, () => {
        useAppLogger().version('NEW URL:', route.fullPath);
        useAppVersion().getData((data: any) => {
            checkVersionByCookies(data);
        });
    });
    watch(() => storeVersion.needReloadForce, () => {
        useAppLogger().version('needReloadForce');
        $deleteCookie(cookieBasicOptions.NAMES.VERSION);
        $deleteCookie(cookiesPinia.NAMES.INTERFACE);
        useStoreInterface().clear();
        useStoreConfig().clear();
        location.reload();
    });
}
</script>
