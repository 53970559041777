<template>
    <div id="common-theme" />
</template>

<script setup lang="ts">

import { useNuxtApp } from 'nuxt/app';
import { watch } from 'vue';
import cookieBasicOptions from '@/plugins/cookies/cookieBasicOptions';
import useAppLogger from '@/composables/useAppLogger';
import { useStoreSystem } from '@/stores/system';
import { useColorMode } from '#imports';

const { $setCookie, $getCookie } = useNuxtApp();
const colorMode = useColorMode();
useAppLogger().force(colorMode.preference);

const storeSystem = useStoreSystem();
if (storeSystem.server) {
    const theme = $getCookie(cookieBasicOptions.NAMES.THEME);
    if (theme !== null) {
        storeSystem.theme = $getCookie(cookieBasicOptions.NAMES.THEME);
        colorMode.preference = storeSystem.theme;
    }
}
if (process.client) {
    if (colorMode.preference === 'system') {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            storeSystem.theme = 'dark';
        } else {
            storeSystem.theme = 'light';
        }
        $setCookie(cookieBasicOptions.NAMES.THEME, storeSystem.theme, cookieBasicOptions.OPTIONS);
    }
    colorMode.preference = storeSystem.theme;
    watch(() => storeSystem.theme, () => {
        useAppLogger().theme(storeSystem.theme);
        $setCookie(cookieBasicOptions.NAMES.THEME, storeSystem.theme, cookieBasicOptions.OPTIONS);
        colorMode.preference = storeSystem.theme;
    });
}
</script>
