<template>
    <div id="common-after-container">
        <ClientOnly>
            <TheSidebarCommon />
        </ClientOnly>
        <TheNotificationCommon />
        <TheCookieBarCommon />
        <TheSearchCommon />
        <!--        <TheModalCommon />-->
        <!--        <ThePanelCommon />-->
        <!--        <TheEchoCommon />-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import TheCookieBarCommon from '@/components/Common/TheCookieBarCommon.vue';
import TheNotificationCommon from '@/components/Common/TheNotificationCommon.vue';
import TheSearchCommon from '@/components/Common/TheSearchCommon.vue';
import TheSidebarCommon from '@/components/Common/TheSidebarCommon.vue';
import { useStoreSystem } from '@/stores/system';

export default defineComponent({
    name: 'TheAfterCommon',
    components: {
        TheSearchCommon,
        TheSidebarCommon,
        TheNotificationCommon,
        TheCookieBarCommon,
    },
    setup() {
        const system = useStoreSystem();

        return {
            system,
        };
    },
});
</script>
