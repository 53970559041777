<template>
    <div id="common-dev" />
</template>

<script setup lang="ts">

import { useNuxtApp } from 'nuxt/app';
import { watch } from 'vue';
import { useRoute } from 'vue-router';
import cookieBasicOptions from '@/plugins/cookies/cookieBasicOptions';
import { useStoreConfig } from '@/stores/app/config';
import { useStoreAppDataIndex } from '@/stores/app/data';
const route = useRoute();

const { $setCookie, $getCookie, $deleteCookie } = useNuxtApp();

const storeData = useStoreAppDataIndex();
const storeConfig = useStoreConfig();

function checkToken() {
    const cookie = $getCookie(cookieBasicOptions.NAMES.DEVELOPER_TOKEN);
    if (cookie === null) {
        return;
    }
    console.log('_token  :', cookie);
    storeConfig.fetchDeveloperCheck({
        token: cookie,
    }, () => {
        //
    }, () => {
        $deleteCookie(cookieBasicOptions.NAMES.DEVELOPER_TOKEN);
    });
}

if (process.client) {
    checkToken();
    watch(() => route.fullPath, () => {
        if (storeConfig.developer.enabled) {
            checkToken();
        }
    });
}

// console.log('common:data:loaded', storeData.loaded);
// if (!storeData.loaded) {
//     useAppData().setData((data: any) => {
//         console.log('data', data.config);
//         storeData.setData(data);
//     });
// }

</script>
